@font-face {
  font-family: 'LightPoppins';
  src: url('./Media/fonts/Poppins//Light-poppins/Poppins-Light.eot');
  src: url('./Media/fonts/Poppins/Light-poppins/Poppins-Light.eot') format('embedded-opentype'),
    url('./Media/fonts/Poppins/Light-poppins/Poppins-Light.woff2') format('woff2'),
    url('./Media/fonts/Poppins/Light-poppins/Poppins-Light.woff') format('woff'),
    url('./Media/fonts/Poppins/Light-poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Media/fonts/Poppins/Regular-poppins/Poppins-Regular.eot');
  src: url('./Media/fonts/Poppins/Regular-poppins/Poppins-Regular.eot') format('embedded-opentype'),
    url('./Media/fonts/Poppins/Regular-poppins/Poppins-Regular.woff2') format('woff2'),
    url('./Media/fonts/Poppins/Regular-poppins/Poppins-Regular.woff') format('woff'),
    url('./Media/fonts/Poppins/Regular-poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'MediumPoppins';
  src: url('./Media/fonts/Poppins/Medium-poppins/Poppins-Medium.eot');
  src: url('./Media/fonts/Poppins/Medium-poppins/Poppins-Medium.eot') format('embedded-opentype'),
    url('./Media/fonts/Poppins/Medium-poppins/Poppins-Medium.woff2') format('woff2'),
    url('./Media/fonts/Poppins/Medium-poppins/Poppins-Medium.woff') format('woff'),
    url('./Media/fonts/Poppins/Medium-poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'boldPoppins';
  src: url('./Media/fonts/Poppins/Bold-poppins/Poppins-Bold.eot');
  src: url('./Media/fonts/Poppins/Bold-poppins/Poppins-Bold.eot') format('embedded-opentype'),
    url('./Media/fonts/Poppins/Bold-poppins/Poppins-Bold.woff2') format('woff2'),
    url('./Media/fonts/Poppins/Bold-poppins/Poppins-Bold.woff') format('woff'),
    url('./Media/fonts/Poppins/Bold-poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'poppins';
}

.header-secton {
  background-color: #42495B;
}

.logo-section {
  padding: 30px 0px;
  display: flex;
  align-items: center;
}
.logo-section>a>img{
  height:62px;
}
.router-links {
  display: flex;
  align-items: end;
  justify-content: center;
  color: white;
  padding: 15px 0px;
}

.router-links>ul {
  list-style-type: none;
}

.router-links>ul>li {
  font-size: 14px;
  line-height: 21px;
  display: inline;
  padding: 0px 20px;
}

.router-links>ul>li>a {
  color: white;
  text-decoration: none;
}

.router-links>ul>li>a.active {
  border-bottom: 4px solid #7EAF00;
  padding-bottom: 14px;
}

.media-section {
  padding: 30px 0px 20px;
}

.media-upper-section {
  color: white;
  text-align: center;
}

.media-upper-section>ul {
  list-style: none;
}

.media-upper-section>ul>li {
  display: inline;
  padding: 0px 15px;
  font-size: 12px;
  line-height: 18px;
}

.media-upper-section>ul>li>a {
  font-size: 12px;
  line-height: 18px;
  color: white;
  text-decoration: none;
}

.media-upper-section>ul>li>a.mediaActive {
  color: #7EAF00;
}

.media-upper-section>ul>li>a>i {
  padding-right: 5px;
}

.media-upper-section>ul>li>i {
  padding-right: 5px;
}

.contact-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}

.contact-button>div {
  background: linear-gradient(284.08deg, #A8E805 -6.44%, #7EAF00 48.46%);
  text-align: center;
  color: white;
  border-radius: 30px;
  padding: 8px 51px 11px;
  width: fit-content;
  font-weight: 700;
}



.footer-container {
  padding: 70px 0px 60px;
}

.footer-logo {
  text-align: center;
}

.leftFooter-section>h6 {
  font-size: 31px;
  line-height: 46px;
  font-weight: 600;
  color: #009ccb;
  padding-bottom: 20px
}

.leftFooter-section>div>a>img {
  padding-right: 15px;
}

.footer-right-section {
  display: flex;
  align-items: center;
  justify-content: end;
}

.footer-right-section>ul {
  list-style-type: none;
}

.footer-right-section>ul>li {
  padding-bottom: 15px;
}

.footer-right-section>ul>li>a {
  font-size: 16px;
  line-height: 24px;
  color: #42495B;
  text-decoration: none;
}

.footer-right-section>ul>li>a>div {
  width: 50px;
  text-align: center;
  float: left;
}



.hero-secton {
  background-image: url(./Media/Images/about-banner.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-content {
  text-align: center;
  padding: 150px 225px 200px;
}
.hero-content>h6 {
  color: #009CCB;
  font-size: 64px;
  line-height: 70px;
}

.hero-content>h1 {
  font-size: 45px;
  line-height: 55px;
  /* font-weight: 700; */
  font-family: 'boldPoppins';
  color: white;
}

.hero-content>p {
  font-size: 20px;
  line-height: 30px;
  color: white;
  font-family: 'LightPoppins';
}

.submit-button>button {
  background-color: #7EAF00;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  color: white;
  padding: 5px 35px 6px;
  border-radius: 30px;
  border: 1px solid #7EAF00;
  font-size: 20px;
}

.get-start {
  padding-top: 50px;
}

.about-container {
  background-image: url(./Media/Images/building.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0px;
}

.about-content {
  color: white
}

.about-content>h4 {
  font-size: 40px;
  line-height: 50px;
  font-family: 'boldPoppins';
}

.about-content>h6 {
  font-size: 25px;
  line-height: 37px;
  font-weight: 700;
  padding-bottom: 20px;
}

.about-content>p {
  font-size: 18px;
  line-height: 27px;
  font-family: 'LightPoppins';
}

.learn-more {
  padding-top: 16px;
}




.contact-container {
  background-image: url(./Media/Images/contact-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 45px 0px;
}

.contactForm-section {
  background-color: #27acd5db;
  border-radius: 25px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding: 45px 45px 10px;
  color: #FFFFFF;
}

.contactUs-Title {
  font-size: 35px;
  line-height: 50px;
  font-family: 'boldPoppins';
  padding-bottom: 33px;
  text-align: center;
}

.submit-form>div {
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: 35px;
}

.submit-form>div>label {
  font-size: 20px;
  line-height: 30px;
  /* font-weight: 700; */
  font-family: 'boldPoppins';
}

.submit-form>div>p {
  font-size: 20px;
  line-height: 33px;
  font-weight: 700;
}

.submit-form>div>textarea {
  resize: none;
  height: 80px;
  width: -webkit-fill-available;
  margin-left: 0px;
}

.input-text {
  margin-left: 10px;
  background: transparent;
  border: none;
  width: 65%;
  color: white;
  font-size: 20px;
  line-height: 24px;
}

.input-text:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}

.checkbox-section {
  border-bottom: none !important;
}
.innerCheckbox-section{
  display: flex;
  padding-bottom: 10px;
}
.innerCheckbox-section>label {
  font-size: 18px !important;
  line-height: 26px !important;
  font-family: 'LightPoppins' !important;
}
.innerCheckbox-section>label>a{
  color: #FFFFFF;
}
.innerCheckbox-section>input {
  margin: 2px 14px 2px 2px;
}

input[type=checkbox] {
  width: 30px;
  height: 30px;
  float: left;
}

input[type=checkbox]:checked {
  accent-color: #7EAF00;
}

.checkbox-section>p {
  font-size: 16px;
  line-height: 24px;
}

.submit-btn {
  text-align: center;
  margin-top: 40px;
  font-size: 27px;
  line-height: 40px;
  border-bottom: none !important;
}

.text-start {
  font-size: 14px !important;
  margin: 0px;
  color: firebrick;
  line-height: 20px !important;
  font-weight: 700;
}

.spinner-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  margin-left: 10px;
}

.subscribemail-text {
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-family: 'boldPoppins';
}


.services-container {
  background-image: url(./Media/Images/services-conatienr.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 0px 80px 0px;
}

.service-header {
  text-align: center;
}

.service-header>h2 {
  font-size: 40px;
  line-height: 50px;
  font-family: 'boldPoppins';
}

.service-header>h6 {
  font-size: 16px;
  line-height: 24px;
  font-family: 'boldPoppins';
  color: #009CCB;
}

.service-header>p {
  font-size: 16px;
  line-height: 24px;
  font-family: 'MediumPoppins';
  padding: 0px 20px 20px;
}

.social-header>p {
  padding: 0px 80px 20px;
}

.card-shade {
  position: relative;
  margin: 20px 0px;
}

.spacing-section {
  border-radius: 45px;
  box-shadow: 0px 10px 20px 0px #00C6BB26;
  margin: 0px 8px;
  height: 100%;
  background-color: #FFFFFF;
}

.servicecard-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 230px;
  border-radius: 37px 37px 0px 0px
}

.servicecard-image>img {
  border-radius: 37px 37px 0px 0px;
  width: 100%;
}

.card-detals {
  padding: 20px 30px;
  background-color: #FFFFFF;
  border-radius: 0px 0px 20px 20px;
}

.card-detals>h4 {
  font-size: 20px;
  line-height: 28px;
  font-family: 'boldPoppins';
  color: #009CCB;
  text-transform: uppercase;
}

.card-detals>ul {
  list-style: none;
}

.card-detals>ul>li {
  font-size: 15px;
  line-height: 18px;
  padding-bottom: 8px;
}
.card-detals>ul>li:last-child{
  padding:0px 145px 8px 0px;
}

.card-learnmore {
  position: absolute;
  right: 22px;
  bottom: 15px;
}

.card-learnmore>button {
  padding: 8px 18px;
}

.project-conatiner {
  background-color: #4DB8D8;
  padding: 30px 0px;
}

.projectportfolio-header {
  text-align: center;
}

.projectportfolio-header>h6 {
  font-size: 16px;
  line-height: 24px;
  font-family: 'boldPoppins';
  color: rgba(0, 156, 203, 1);
}

.projectportfolio-header>h2 {
  font-size: 40px;
  line-height: 50px;
  font-family: 'boldPoppins';
  color: #FFFFFF;
  margin: 0px;
}

.projectportfolio-header>p {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  font-family: 'LightPoppins';
  padding: 0px 80px 20px;
}

.slider-img {
  padding-top: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 80px 10px 0px;
  border-radius: 37px 37px 0px 0px;
}

.slideShade-dash {
  width: -webkit-fill-available;
}

audio {
  width: 100%;
}

audio::-webkit-media-controls-panel {
  background-color: #ffffff;
}

audio::-webkit-media-controls-play-button {
  background-color: rgba(126, 175, 0, 1);
  border-radius: 50%;
}

audio::-webkit-media-controls-play-button:hover {
  background-color: rgba(126, 175, 0, 1);
}
.play-media{
  display: flex;
  align-items: center;
}
.slider-section {
  position: relative;
}

.slider-details {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  color: #FFFFFF;
  text-align: center;
}

.slider-details>h3 {
  font-size: 17px;
  line-height: 20px;
  font-weight: 700;
  padding:0px 20px 10px;
}

.slider-details>p {
  font-size: 14px;
  line-height: 20px;
  font-family: 'LightPoppins';
  padding: 0px 30px 27px;
}

.slick-current .slider-section .slider-img {
  padding-top: 270px;
  margin-top: 0px;
}

.slider-arrow {
  margin-top: 24px;
}

.media-detals>h4 {
  color: rgba(38, 34, 34, 1);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 60px;
}

.media-detals>p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 100px;
  margin: 0px;
  font-size: 16px;
  line-height: 24px;
}

.media-detals>p>p>a {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0px;
  color: black;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
}

.media-detals>p>h2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0px;
  color: black;
  font-size: 16px;
  line-height: 24px;
}

.media-learnmore {
  position: static;
  text-align: end;
  padding-top: 36px;
}

.media-spacing {
  margin: 0px 20px;
}


/* services */

.servicesHero-secton {
  background-image: url(./Media/Images/services-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.services-section {
  padding: 170px 15px 210px;
}

.rules-container {
  background-image: url(./Media/Images/rules.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 62px 0px 32px;
}

.rulesContent-section {
  text-align: center;
  color: #FFFFFF;
  padding: 0px 33px;
}

.rulesContent-section>h6 {
  font-size: 18px;
  line-height: 27px;
  font-family: 'LightPoppins';
}

.rulesContent-section>h5 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 300;
}

.real-estateContainer {
  background-image: url(./Media/Images/real-estateBackground.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0px 50px;
}

.realEstate-left {
  padding: 20px 0px;
}

.realEstate-left>h2 {
  font-size: 40px;
  line-height: 50px;
  font-family: 'boldPoppins';
  color: #009CCB;
}

.realEstate-left>ul {
  list-style: none;
}

.realEstate-left>ul>li {
  font-size: 20px;
  line-height: 32px;
  font-weight: 300;
  display: flex;
}

.realEstate-left>ul>li>img {
  padding-right: 15px;
}


.municipal-container {
  background-image: url(./Media/Images/shade.png);
  background-color: #CFECF5;
}

.other-services {
  display: flex;
  justify-content: center;
  flex-direction: column;
}



.Bio-section {
  background-image: url(./Media/Images/bio-background.png);
}

.Bio-content {
  padding: 200px 80px 110px;
}

.bio-container {
  background-image: url(./Media/Images/bio-shade.png);
}

.consulting-services>h2 {
  font-size: 32px;
  line-height: 40px;
  color: #42495B;
  font-family: 'boldPoppins';
  padding-bottom: 10px;
}



.partners-container {
  background-image: url(./Media/Images/partners-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.partner-profile {
  background-image: url(./Media//Images/Bob.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 50%;
}

.partner-details {
  padding: 90px 60px 60px 535px;
  color: #FFFFFF;
}

.partner-details>h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.partner-details>h2 {
  font-size: 45px;
  line-height: 55px;
  font-family: 'boldPoppins';
}

.partner-details>h6 {
  font-size: 18px;
  line-height: 27px;
  font-family: 'LightPoppins';
}

.partner-details>p {
  padding-top: 15px;
  font-size: 18px;
  line-height: 24px;
  font-family: 'LightPoppins';
}


.custom-container {
  min-width: auto;
  max-width: 1366px;
}

.svComment-container {
  background-image: url(./Media/Images/comment-shade.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #42495B;
  padding: 60px 0px;
}

.svComment-content {
  color: #FFFFFF;
  padding: 0px 50px;
}

.svComment-content>p {
  font-size: 18px;
  line-height: 28px;
  font-family: 'LightPoppins';
  text-align: center;
}

.opencolon {
  position: relative;
  right: 14px;
  bottom: 10px;
}

.closecolon {
  position: relative;
  top: 10px;
  left: 12px;
}

.portfolio-container {
  background-image: url(./Media/Images/portfolio-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0px 105px;
}

.portfolio-header {
  text-align: center;
  color: #FFFFFF
}

.portfolio-header>h6 {
  font-size: 29px;
  line-height: 43px;
}

.portfolio-header>h1 {
  font-size: 45px;
  line-height: 55px;
  font-weight: 700;
  font-family: 'boldPoppins';
  padding-bottom: 20px;
}

.portfolio-outer {
  padding: 15px 0px 32px;
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
}

.portfolio-spacing {
  padding: 0px 30px;
}

.portfolio-spacing>a {
  color: black;
  text-decoration: none;
}

.portfolio-img {
  border-radius: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 220px;
}

.portfolio-slider-spacing {
  margin-bottom: 32px;
}

.portfolio-slider-spacing>a {
  color: black;
  text-decoration: none;
}

.portfolio-content {
  background: rgb(238 242 242 / 66%);
  text-align: center;
  border-radius: 50px 50px 0px 0px;
  height: 380px;
}

.portfolio-details {
  padding: 24px 20px 16px;
}

.portfolio-details>h6 {
  font-size: 22px;
  line-height: 30px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.portfolio-details>p {
  font-size: 18px;
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.arrow-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 40px;
  border-radius: 10px;
  margin: 0px 10px;
  color: #086C8A;
  background-color: #DADFEB;
  cursor: pointer;
  border: 2px solid #DADFEB;
}

.icon:hover {
  color: #DADFEB;
  background-color: #086C8A;
  border: 2px solid #086C8A;
}

.cs-Description {
  display: flex;
  align-items: center;
}

.cs-Description>p {
  font-size: 24px;
  line-height: 34px;
  font-family: 'LightPoppins';
  padding: 20px 0px;
}
.titleImage{
  padding:40px;
}
.titleImage>div{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    box-shadow: 20px 4px 31px -8px rgba(0, 0, 0, 0.3);
    border: 4px solid white;
    padding-top: 400px;
}
.social-relative {
  position: relative;
}

.banner-socialLink {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 40%;
  left: -3%;
}

.banner-socialLink>a>img {
  padding-bottom: 15px;
}

.linebar {
  height: 150px;
  width: 5px;
  background-color: #009CCB;
}

.banner-container {
  /* background-image: url(./Media//Images/details-banner.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.shade-section{
    background: rgba(0, 0, 0, 0.6);
}

.banner-title>p {
  font-size: 40px;
  line-height: 55px;
  color: #FFFFFF;
  font-family: 'boldPoppins';
  padding: 370px 500px 50px 0px;
}

.CS-description-detals {
  padding: 20px 0px;
}





.media-container {
  background-image: url(./Media/Images/portfolio-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 48px 0px;
}

.mediaheader-title {
  text-align: center;
  color: #FFFFFF;
  padding-bottom: 10px;
}

.mediaheader-title>h6 {
  font-size: 29px;
  line-height: 43px;
}

.mediaheader-title>h1 {
  font-size: 45px;
  line-height: 55px;
  font-family: 'boldPoppins';
}

.newsmedia-slider-details {
  position: relative;
}

.media-details-section {
  margin: 0px;
  padding-top: 26px;
  display: flex !important;
}

.rightMedia-details-section {
  text-align: right;
  padding: 80px 10px 30px 20px;
}

.rightMedia-details-section>h6 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  font-family: 'boldPoppins';
  padding-bottom: 5px;
}

.rightMedia-details-section>p {
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.rightMedia-details-section>p>p>a {
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  color: #000000;
  text-decoration: none;
}

.rightMedia-details-section>p>h2 {
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  color: #000000;
  text-decoration: none;

}

.activeNewsArrrow {
  position: absolute;
  right: 5px;
  top: 30px;
}

.media-arrow {
  justify-content: end;
  padding-bottom: 24px;
}

.media-images {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 345px;
  ;
  border-radius: 30px;
  box-shadow: 20px 4px 31px -8px rgba(0, 0, 0, 0.3);
  border: 4px solid white;
}

.play-icon {
  position: absolute;
  height: 34%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}


.news-container {
  background-image: url(./Media/Images/services-conatienr.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 86px 0px 120px;
}

.newsMedia-container {
  background-image: url(./Media/Images/newsmedia-container.png);
}

.mediaNews-Container {
  padding: 20px;
}

.container-mediadetails {
  background-image: url(./Media/Images/portfolio-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 48px 0px;
}

.news-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 46%;
  border-radius: 40px;
  box-shadow: 0px 4px 31px -8px rgba(0, 0, 0, 0.55);
}

.media-newsDetails {
  padding: 25px 20px;
}

.media-newsDetails>span {
  background-color: #7EAF00;
  padding: 10px;
  border-radius: 10px;
  color: #FFFFFF;
  margin-top: 5px;
}

.media-newsDetails>h6 {
  font-size: 40px;
  line-height: 50px;
  font-family: 'boldPoppins';
  color: #009CCB;
  padding-top: 20px;
}

.media-newsDetails>p {
  font-size: 22px;
  line-height: 30px;
  text-align: justify;
}

.media-newsDetails>p>h2 {
  font-size: 22px;
  line-height: 30px;

}

.Error-container {
  background-image: url(./Media/Images/shade.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 20px;
}

.Error-section {
  text-align: center;
}

.Error-section>h1 {
  font-family: 'boldPoppins';
  color: #009CCB;
}


.news-silckSlider>.slick-list>.slick-track {
  margin: 0px;
  width: 100%;
}

.slick-slide>div>a {
  color: #000000;
  text-decoration: none;
}






.podcast-card {
  background-color: #FFFFFF;
  box-shadow: 0px 20px 68px 0px rgba(62, 62, 62, 0.212);
  border-radius: 30px;
  padding: 50px 70px;
}

.podcastHeader-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.podcastHeader-title>p {
  font-size: 33px;
  line-height: 33px;
  font-family: 'boldPoppins';
}

.podcastHeader-title>button {
  background-color: #7EAF00;
  border-radius: 10px;
  color: #FFFFFF;
  border: 1px solid #7EAF00;
  padding: 11px 36px;
}

.podcast-section {
  padding: 0px 0px 10px;
}

.podcast-details-section {
  display: flex;
  align-items: center;
}

.podcast_img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  width:-webkit-fill-available;
  max-width: 230px;
  box-shadow: 0px 20px 68px 0px rgba(62, 62, 62, 0.212);
  border-radius: 20px;
  border: 4px solid #ffffff;
}

.podcast-details {
  padding: 20px;
  width:100%;
}
/* .slick-slider>.slick-list>.slick-track>.slick-cloned {
  display: none;
} */
.podcast-details>h6 {
  font-size: 20px;
  line-height: 30px;
  font-family: 'boldPoppins';
  padding-bottom: 10px;
}

.podcast-details>p {
  font-size: 15px;
  line-height: 22px;
  padding-bottom: 10px;
  text-align: justify;
}

.section-details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-left: 2px solid #E5E5E5;
  padding-left: 22px;
}

.section-details>p {
  font-size: 14px;
  line-height: 21px;
  color: rgba(194, 194, 194, 1);
}

.podcast-episode>p {
  padding: 10px 145px 0px 0px;
}

.show-more-btn {
  text-align: center;
  padding: 30px 0px 0px;
}

.show-more-btn>button {
  border: 2px solid #7EAF00;
  color: rgba(126, 175, 0, 1);
  padding: 15px 20px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.conditionsContainer{
  background-image: url(./Media/Images/services-conatienr.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0px;
}
.termTitle{
  font-family: 'boldPoppins';
}
.termPera{
  padding:0px 0px 0px 23px;
  text-align: justify;
}
.subtermPera{
  padding:0px 0px 0px 50px;
  text-align: justify;
}
.subtitleTerm{
  padding:0px 0px 0px 20px;
  font-family: 'boldPoppins';
}
.termsSection>h2{
  text-align: center;
  font-size: 50px;
  line-height: 55px;
  font-family: 'boldPoppins';
  padding-bottom: 20px;
  overflow: hidden;
}


.appContainer{
  background-color: #0b6b5a;
  padding:70px 0px;
}
.main-section {
  color: #7B7B7D;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-section {
  max-width: 90%;
}
.main-logo img {
  height: 100%;
}

.headingText {
  font-size: 22px;
  line-height: 35px;
  padding:5px 0px;
  font-family: 'MediumPoppins';
}

.peraText {
  font-size: 12px;
  line-height: 20px;
  margin-top: 5px;
  padding: 0px 18px;
  font-family: 'MediumPoppins';
}

.appShareLogo {
  margin-bottom: 8px;
}
.appShareLogo a img {
  height: 50px;
}

.shareLink {
  font-size: 14px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shareLink span {
  padding: 0px 8px;
}
.leftsharelink a {
  text-decoration: none;
  color: #7B7B7D;
  font-family: 'MediumPoppins';
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Mobile View */

@media only screen and (max-width: 768px) {

  .titleImage{
    padding:20px;
  }
  .logo-section {
    justify-content: center;
  }

  .router-links>ul>li {
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .router-links>ul>li>a {
    margin-bottom: 10px;
  }

  .router-links>ul>li>a.active {
    padding-bottom: 0px;
    margin-bottom: 10px;
  }

  .banner-socialLink {
    left: 1%;
  }

  .hero-content {
    padding: 50px 28px;
  }
  /* .rulesContent-section>h5{

  } */
  .card-detals>ul>li:last-child{
    padding:0px 150px 8px 0px;
  }
  .hero-content>h6 {
    font-size: 30px;
    line-height: 36px;
  }

  .hero-content>h1 {
    font-size: 34px;
    line-height: 42px;
  }

  .about-container {
    padding: 30px 0px;
  }

  .card-shade {
    margin: 20px 0px;
  }

  .services-container {
    padding: 40px 0px;
  }

  .projectportfolio-header>h2 {
    font-size: 36px;
    line-height: 44px;
  }

  .projectportfolio-header>p {
    padding: 0px 10px 20px;
  }

  .contactForm-section {
    width: 90%;
    padding: 30px 15px;
  }

  .contactUs-Title {
    font-size: 32px;
    line-height: 42px;
  }

  .submit-form>div>label {
    font-size: 18px;
    line-height: 24px;
  }

  .submit-form>div {
    margin-bottom: 28px;
  }

  .checkbox-section>label {
    font-size: 12px !important;
    line-height: 18px !important;
  }

  input[type=checkbox] {
    width: 50px;
    height: 30px;
  }

  .input-text {
    margin-left: 0px;
    width: 100%;
  }

  .footer-right-section {
    justify-content: center;
  }

  .leftFooter-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
  }

  .footer-container {
    padding: 35px 0px;
  }

  .footer-logo {
    padding: 20px 10px 30px;
  }

  .leftFooter-section>h6 {
    padding-bottom: 5px;
  }

  .rulesContent-section {
    padding: 0px 25px;
  }

  .real-estateContainer {
    padding: 20px;
  }

  .realEstate-left>h2 {
    font-size: 28px;
    line-height: 36px;
  }

  .hero-content>p {
    font-size: 18px;
    line-height: 28px;
  }
  .about-content>h4{
    font-size: 36px;
    line-height: 46px;
  }
  .about-content>h6{
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 10px;
  }
  .service-header>h2{
    font-size: 36px;
    line-height: 46px;
  }

  .get-start {
    padding-top: 20px;
  }

  .rules-container {
    padding: 35px 0px;
  }

  .media-section {
    padding: 20px;
  }

  .portfolio-container {
    padding: 50px 0px;
  }

  .portfolio-content {
    height: 340px;
  }

  .portfolio-outer {
    padding: 15px 0px 0px;
  }

  .portfolio-header>h6 {
    font-size: 22px;
    line-height: 26px;
  }

  .portfolio-header>h1 {
    font-size: 36px;
    line-height: 46px;
    padding-top: 10px;
  }

  .portfolio-spacing {
    padding: 15px 20px;
  }

  .portfolio-img {
    padding-top: 190px;
  }

  .portfolio-details {
    padding: 10px;
  }

  .partner-profile {
    background-size: 100%;
  }

  .partner-details {
    padding: 50px 20px 215px;
  }

  .svComment-content {
    padding: 0px 20px;
  }
  .mediaheader-title>h6 {
    font-size: 26px;
    line-height: 36px;
  }
  .mediaheader-title>h1 {
    font-size: 36px;
    line-height: 46px;
  }

  .rightMedia-details-section {
    padding: 20px;
    text-align: center;
  }
  .rightMedia-details-section>h6 {
    font-size: 24px;
    line-height: 26px;
  }

  .news-container {
    padding: 50px 0px;
  }

  .social-header>p {
    padding: 0px 10px 10px;
  }

  .media-container {
    padding: 48px 0px 10px;
  }

  .media-learnmore {
    padding-top: 10px;
  }

  .card-detals {
    padding: 20px;
  }

  .slider-details>p {
    padding: 0px 20px 30px;
  }

  .submit-form>div>p {
    font-size: 18px;
    line-height: 30px;
  }

  .submit-btn {
    font-size: 20px;
    line-height: 30px;
    margin-top: 20px;
  }

  .card-learnmore {
    bottom: 10px;
  }

  .realEstate-left>ul>li {
    font-size: 18px;
    line-height: 28px;
  }

  .mediaNews-Container {
    padding: 15px;
  }

  .media-newsDetails>h6 {
    font-size: 20px;
    line-height: 28px;
  }

  .media-newsDetails>p {
    font-size: 18px;
    line-height: 26px;
  }

  .slider-img {
    margin: 55px 10px 0px;
  }

  .slick-current .slider-section .slider-img {
    padding-top: 200px;
  }

  .media-images {
    padding-top: 180px;
  }

  .activeNewsArrrow {
    position: initial;
  }

  .media-arrow {
    justify-content: center;
    padding-bottom: 10px;
  }
  .service-header>p {
    padding:0px 0px 20px;
  }

  .partner-details>h2 {
    font-size: 32px;
    line-height: 40px;
  }

  .media-details-section {
    display: block !important;
  }

  .podcast-card {
    padding: 30px 10px;
    margin: 0px;
  }

  .podcastHeader-title {
    display: block;
    text-align: center;
  }

  .podcastHeader-title>p {
    font-size: 24px;
    line-height: 24px;
    padding-bottom: 10px;
  }

  .podcast-details-section {
    display: block;
  }

  .podcast_img {
    width: auto;
    max-width: 100%;
  }

  .podcast-episode>p {
    padding: 15px 0px 0px;
  }

  .banner-title>p {
    font-size: 24px;
    line-height: 32px;
    padding: 65px 20px 10px;
  }
  .cs-Description{
    padding:0px 20px;
  }
  .cs-Description>p{
    font-size: 18px;
    line-height: 28px;
  }
  .conditionsContainer{
    padding:0px;
  }
  .termsSection{
    padding:40px 20px;
  }
  .termsSection>h2{
    font-size: 34px;
    line-height: 40px;
   }
   .termPera{
     padding:0px;
   }
   .subtermPera{
     padding:0px;
   }
   .subtitleTerm{
     padding:0px;
   }
   .newsmedia-slider-details{
    padding:0px 20px;
   }
}